import React from "react";
import { Center } from "@chakra-ui/react";

import Species from "../ui/mainPage/Species";
import Layout from "../components/Layout";

const SpeciesTemplate = (props) => {
  const { pageContext } = props;
  const { speciesOptions } = pageContext;

  const breadcrumb = [["Biology", "/journals/biology"]];

  const species = (
    <Center paddingTop="100px">
      <Species speciesOptions={speciesOptions} breadcrumb={breadcrumb} />
    </Center>
  );
  return <Layout children={species} />;
};

export default SpeciesTemplate;
